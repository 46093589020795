.client-app header {
  height: 7dvh;
  display: flex;
  color: white;
  padding-top: 2dvh;
  padding-right: 2dvh;
  padding-left: 2dvh;
  margin-bottom: 1dvh;
  z-index: 20;
}

.box {
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.box img {
  max-width: 30%;
}

.logo-company {
  height: 100%;
  max-width: 100% !important;
}

.box span {
  max-width: 70%;
  line-height: 0 !important;
}

.box div {
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  gap: 5px;
}
