.leader-badge {
  display: flex;
  justify-content: center;
  width: 13dvw;
  height: 3dvh;
  background: #2d4203;
  border-radius: 24px;
}

.leader-badge-text {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: #b3f131;
}

.player-name-text {
  font-family: 'Radio Canada Big';
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 100%;
  display: flex;
  align-items: center;
  color: #ffffff;
}

.name-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1dvh;
  width: 100%;
}

.avatar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 2dvh;
}

.filler {
  display: flex;
  width: 100%;
  height: 6dvh;
}

.language-container {
  padding-top: 2dvh;
  padding-left: 6dvw;
  padding-right: 6dvw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1dvh;
  width: 100%;
  margin-bottom: 4dvh;
}

.language-title-container {
  display: flex;
  padding-top: 2dvh;
  padding-left: 6dvw;
  width: 100%;
}

.language-title {
  font-family: 'Radio Canada Big';
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 100%;
  color: #ffffff;
}

.language-container > div {
  text-align: center;
  font-size: xxx-large;
  background: #171a1e;
  border: 1px solid var(--neutrals-03);
  border-radius: 0.35em;
  width: 41dvw;
}

.language-container > div > p {
  font-size: medium;
  font-family: 'Space Grotesk';
  font-weight: normal;
}

.language-container > div.active {
  border: 3px solid #7bb600;
  background: linear-gradient(0deg, rgba(179, 241, 49, 0.15), rgba(179, 241, 49, 0.15)),
    linear-gradient(180deg, #1b1b21 0%, #292f36 100%);
}

.language-container > div.active > p {
  color: #b3f131;
}
