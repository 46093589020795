.chest {
  min-height: 5dvh;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1dvh;
}

.chest-tab-container {
  padding: 0.5dvh;
  margin: 0 5dvw;
  border-radius: 1.5dvh;
  background-color: var(--x-02);
}

.chest-tab {
  border-radius: 4dvw;
  width: 50%;
  text-align: center;
  display: inline-block;
  color: var(--x-06);
}

.chest-tab button {
  box-shadow: none;
  padding: 2dvw 1dvw;
  width: 100%;
  height: 100%;
  color: var(--x-08);
  background-color: inherit;
  border-radius: 1.2dvh;
  margin-bottom: 0;
}

.chest-tab img {
  height: 5dvw;
  padding-right: 2dvw;
  margin-bottom: 3px;
  opacity: 50%;
}

.chest-tab-active {
  background-color: var(--x-01);
  opacity: 100%;
}

.chest-tab-active button {
  color: var(--x-06);
}

.chest-tab-active img {
  opacity: 100%;
}

.chest-container {
  position: relative;
  margin-left: 1dvw;
  margin-right: 1dvw;
  padding: 2dvw;
  display: flex;
  flex-direction: column;
  gap: 2dvh;
  height: 90%;
}

.img-chest-container {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 70dvh;
  justify-content: start;
  gap: 2dvh;
}

.img-chest-container.can-open {
  justify-content: center;
}

.chest .button-chest.disable {
  background-color: var(--x-03);
  color: var(--x-06);
  box-shadow: none;
}

.prizes-tittle {
  font-size: 3rem;
  margin: 1rem;
}

.prizes-container {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.prizes {
  width: 20rem;
}

.carousel-container {
  background-color: var(--x-02);
  position: relative;
  margin-left: 2dvw;
  margin-right: 2dvw;
  padding: 3dvw;
  border-radius: 2dvw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2dvh;
  height: 100%;
}

.carousel-container .carousel-item-title {
  text-align: center;
}

.carousel-container .carousel-item-title > p {
  font-size: 1em;
  font-weight: 400;
  line-height: 0;
  color: var(--x-03);
}

.carousel-container .carousel-item-image {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 45%;
}

.carousel-container .carousel-item-image .carousel-control-container {
  display: flex;
  align-items: center;
}

.carousel-container .carousel-item-image button {
  width: 10vmin;
  height: 8.5vmin;
  border-radius: 50%;
  background-color: var(--x-04);
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-item-image button[disabled] {
  background-color: inherit;
  color: var(--x-03);
}

.img-piece-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / 1;
}

.img-piece-container .orbit-piece {
  width: 96%;
  height: auto;
  z-index: 1;
}

.img-piece-container .img-piece {
  position: absolute;
  z-index: 2;
  width: 100%;
  padding: 2dvh;
  border-radius: 50%;
  height: 100%;
  object-fit: cover;
}

.carousel-item-indicators {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: end;
  gap: 10px;
  height: 10%;
}

.carousel-item-indicators .dot {
  width: 3dvw;
  height: 3dvw;
  background-color: var(--x-03);
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.carousel-item-indicators .dot.active {
  background-color: var(--x-04);
}

.carousel-item-pieces {
  width: 100%;
  height: 18vh;
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 1dvh;
}

.carousel-container .carousel-item-pieces .jigsaw-parts {
  align-self: center;
  width: 25%;
}

.carousel-container .carousel-item-pieces h1 {
  font-size: 2rem;
  font-weight: 900;
}

.carousel-item-tickets {
  width: 100%;
  height: 18vh;
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  position: relative;
}

.carousel-container .carousel-item-tickets .jigsaw-parts {
  display: flex;
  justify-content: center;
  align-self: center;
  width: 30%;
  gap: 1dvh;
}

.carousel-container .carousel-item-tickets h1 {
  font-size: 1.5rem;
  font-weight: 900;
}

.carousel-container .carousel-item-tickets .jigsaw-parts h1 {
  font-size: 2rem;
  font-weight: 900;
}

.carousel-container .carousel-item-tickets .jigsaw-parts img {
  width: 30%;
}

@media only screen and (max-width: 600px) {
  .carousel-container {
    gap: 0.5dvh;
  }

  .carousel-item-title p {
    margin-bottom: 5px;
  }

  .carousel-item-pieces h1 {
    margin-bottom: 0px;
  }

  .jigsaw-parts {
    width: 45%;
    height: 45%;
  }

  .carousel-item-tickets {
    height: 12vh;
  }
}

.chest-countdown-container {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  padding-left: 2dvw;
  padding-right: 2dvw;
  margin-bottom: 10dvh;
}

.chest-countdown-container .img-countdown {
  width: 100%;
}

.chest-countdown {
  position: absolute;
  margin-top: 5dvw;
}

.chest-countdown-legend {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.chest-countdown-legend > span {
  font-family: var(--secondary-font) !important;
}

.chest-countdown-clock {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: var(--x-16);
  font-size: 12dvw;
  line-height: 1em;
}

.img-chest {
  width: 65%;
}

.img-chest-container > button {
  width: 75%;
}

.img-chest-container .button-rewards {
  box-shadow: none;
  color: var(--x-05);
  width: 70dvw;
  background-color: inherit;
  outline: 0;
}

.button-rewards .rewards-icon {
  display: inline;
  font-size: 4dvw;
  color: var(--x-05);
}
