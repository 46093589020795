.game-info {
  height: 100%;
  font-family: var(--primary-font);

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 8dvh 0 8dvh 0;
}

.last-game-stats {
  width: 75dvw;
}

.game-info-title {
  text-align: center;
}

.game-info-title > h1 {
  font-size: 2em;
}

.game-info-title > span {
  color: var(--x-10);
}

.info-pills {
  margin-top: 4dvh;

  display: flex;
  justify-content: space-between;
  gap: 3dvw;
}

.info-pills span {
  font-family: var(--secondary-font);
  font-weight: 500;
}

.info-pills strong {
  font-family: var(--primary-font);
  font-size: 1.1em;
  font-weight: 600;
}

.info-pills > div {
  line-height: 1.75em;
  width: 50%;

  background: var(--neutrals-02);
  border: 1px solid var(--neutrals-03);
  border-radius: 0.7em;
  text-align: center;
}

.overall-stats {
  height: 45dvh;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.action-button {
  width: 70dvw;
}

.stats-item {
  height: 10dvh;
  width: 55dvw;

  background: var(--neutrals-02);
  border: 1px solid var(--neutrals-03);
  border-radius: 0.7em;
  text-align: center;

  display: flex;
}

.stats-item-placeholder {
  height: 10dvh;
  width: 55dvw;
  color: var(--x-12);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.stats-item-placeholder > img {
  height: 2em;
}

.stats-item-placeholder > span {
  font-family: var(--secondary-font);
  font-weight: 400;
}

.stats-item.highlighted {
  border: 1px solid var(--x-12);
}

.stats-item-icon {
  width: 40%;
  padding: 0.8em;
}

.stats-item-icon > img {
  width: auto;
  height: 100%;
}

.stats-item-info {
  text-align: left;
  width: 60%;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.stats-item-info-value {
  font-size: 1.35em;
  font-weight: 500;
  line-height: 1em;
  color: var(--neutrals-07);
}

.stats-item-info-title {
  font-family: var(--secondary-font);
  font-weight: 400;
  line-height: normal;
  color: var(--neutrals-04);
}
