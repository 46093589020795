.bonus {
  display: flex;
  flex-direction: column;
  background-color: black;
  align-items: center;
  min-height: 100dvh;
}

.bonus-inner {
  margin: 2dvh 4dvw 2dvh 4dvw;
  display: flex;
  flex-flow: column;
  gap: 3dvh;
  width: 90%;
}

.bonus-body {
  height: 75dvh;
  background: linear-gradient(180deg, #101010 0%, #292f36 100%);
  border-radius: 8px;
  display: flex;
  flex-flow: column;
}

.bonus-title {
  font-family: 'Radio Canada Big';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 100%;
  text-align: center;
  color: #ffffff;
}

.bonus-title-1 {
  margin-top: 8dvh;
}

.bonus p {
  font-family: 'Radio Canada Big';
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 100%;
  text-align: center;
  color: #c7ccd4;
}

.bonus-hits {
  margin-top: 3%;
  font-family: 'Radio Canada Big';
  font-style: normal;
  font-weight: 700;
  font-size: 72px;
  text-align: center;
  color: #abe3f8;
}

.bonus-img {
  align-self: center;
  height: 26dvh;
}

.bonus-quantity {
  position: relative;
  background: #353d45;
  border-radius: 50%;
  font-family: 'Radio Canada Big';
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 100%;
  text-align: center;
  color: white;
  align-content: center;
  top: -32%;
  left: 68%;
  width: 9dvw;
  height: 4dvh;
}

.bonus-button {
  width: 78dvw;
  align-self: center;
}
