.rewards-navigator {
  min-height: 5dvh;
  background-color: var(--x-02);
  border-radius: 1.5dvh;
}

div.rewards-navigator,
.rewards-navigator button {
  padding: 1dvw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1.5dvw;
  color: var(--x-06);
}

.rewards-navigator button {
  width: 100%;
  height: 4dvh;
  background-color: var(--x-01);
  border-radius: 1.2dvh;
  margin-bottom: 0;
}

.rewards-navigator button img {
  max-height: 2.3dvh;
}

.rewards-navigator button span {
  line-height: 4dvh;
}

button.disabled {
  color: var(--x-03);
  background-color: var(--x-02);
}

button.disabled img {
  filter: invert(40%) sepia(34%) saturate(265%) hue-rotate(147deg) brightness(60%) contrast(60%);
}

.active-tab {
  visibility: visible;
  display: block;
}

.inactive-tab {
  visibility: hidden;
  display: none;
}
