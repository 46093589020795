.select-image__grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 12px;
  padding: 10px;
  justify-content: center;
}

.select-image__item {
  cursor: pointer;
  border: 2px solid transparent;
  text-align: center;
  transition: all 0.2s ease-in-out;
  background-color: var(--neutrals-07);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.select-image__item:hover {
  border: 2px solid #198754;
}

.select-image__thumbnail {
  width: 100%;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
}

.select-image__item--selected {
  border: 2px solid #198754 !important;
}

.select-image__preview {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
  aspect-ratio: 1/1;
}

.select-image__name {
  margin-top: 5px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}
