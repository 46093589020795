:root {
  --shadow-button: -2px 5px 0px 0px rgba(97, 107, 110, 1);
  --x-01: rgba(5, 18, 22, 1);
  --x-02: rgba(29, 37, 42, 1);
  --x-03: rgba(84, 101, 111, 1);
  --x-04: rgba(179, 241, 49, 1);
  --x-05: rgba(119, 203, 14, 1);
  --x-06: rgba(255, 255, 255, 1);
  --x-07: rgba(240, 242, 245, 1);
  --x-08: rgba(171, 175, 181, 1);
  --x-09: rgba(209, 241, 251, 1);
  --x-10: rgba(171, 227, 248, 1);
  --x-11: rgba(105, 193, 226, 1);
  --x-12: rgba(255, 239, 124, 1);
  --x-13: rgba(236, 207, 7, 1);
  --x-16: rgba(255, 192, 192, 1);
  --x-17: rgba(255, 120, 120, 1);
  --x-18: #bbf33829;
  --x-19: #ff787859;
  --x-20: #747c8a;
  --x-21: #382629;
  --x-22: #2d4203;
  --x-23: #c7ccd4;

  --neutrals-01: #101010;
  --neutrals-02: #171a1e;
  --neutrals-03: #292f36;
  --neutrals-04: #747c8a;
  --neutrals-05: #c7ccd4;
  --neutrals-06: #FOF2F5;
  --neutrals-07: #ffffff;
}
