.timer-container {
  background-color: transparent;
  width: 100%;
  margin-top: 1dvh;
  z-index: 20;
  max-height: 50px;
}

.timer-container > svg {
  display: block;
  margin: auto;
}

.timer-fill {
  height: 100%;
  border-radius: 10px;
  border-width: 3px 3px;
  z-index: 21;
}

.timer-fill .normal {
  background-color: #07a44980;
}

.timer-fill .warning {
  background-color: #ffc60680;
}

.timer-fill .danger {
  background-color: #fb4d4c80;
}

div.custom-timer-text.normal,
div.custom-timer-text.game-normal {
  color: var(--x-04);
}

div.custom-timer-text.warning {
  color: var(--x-12);
}

div.custom-timer-text.danger {
  color: var(--x-16);
}

.custom-timer-text {
  font-size: x-large;
  text-align: center;
  position: relative;
  height: 50px;
  top: -50px;
  line-height: 50px;
}
