.orbit-circle {
  stroke: #54656f;
  stroke-width: 0.6;
}

.spinner-circle {
  transform-origin: center;
  fill: white;
}

.normal-inner-circle {
  animation: spin 30s linear infinite;
  fill: #77cb0e;
}

.warning-inner-circle {
  animation: spin 8s linear infinite;
  fill: #eccf07;
}

.danger-inner-circle {
  animation: spin 2s linear infinite;
  fill: #ff7878;
}

.normal-outer-circle {
  animation: spin 32s linear infinite;
  fill: #77cb0e;
}

.warning-outer-circle {
  animation: spin 10s linear infinite;
  fill: #eccf07;
}

.danger-outer-circle {
  animation: spin 3s linear infinite;
  fill: #ff7878;
}

.loading-normal-inner-circle {
  animation: spin 10s linear infinite;
  fill: #77cb0e;
}

.loading-normal-middle-circle {
  animation: spin 20s linear infinite;
  fill: #77cb0e;
}

.loading-normal-outer-circle {
  animation: spin 30s linear infinite;
  fill: #77cb0e;
}

.normal-background {
  fill: #1d252a;
}

.normal-in-game-background {
  fill: #2d4300;
}

.warning-background {
  fill: #3c3505;
}

.danger-background {
  fill: #37262f;
}

.danger-background-transparent {
  fill: rgba(55, 38, 42, 0.8);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
