button {
  border-style: none;
}

.gali-button {
  border: 0;
  padding: 0;
  height: 6dvh;
  width: 100%;
  box-shadow: -0.75dvw 0.5dvh 0 var(--x-08);
  border-radius: 3dvh;

  font-family: var(--secondary-font) !important;
  font-size: 1.313rem;
  cursor: pointer;
  color: var(--x-01);
  text-align: center;
  margin-bottom: 1dvh;
}

.open {
  background-color: var(--x-06);
}

.primary {
  background-color: var(--x-04);
}

.error {
  background-color: var(--x-17);
}

.locked {
  color: var(--x-08);
  background-color: var(--x-21);
  box-shadow: none;
}

.unavailable {
  color: var(--x-07);
  background-color: var(--x-08);
  box-shadow: none;
}
