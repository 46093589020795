.home-container {
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.experience-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
}

.experience-header.fixed {
  margin: 0;
  padding: 0;
}

.timer-container.home {
  margin-top: unset;
}

.timer-container.home > svg {
  margin-left: calc((100dvw - 400px) / 2);
}

.custom-timer-text.home-danger {
  color: var(--x-16);
}

.custom-timer-text.home-normal {
  color: var(--x-04);
  height: 50px;
  top: -50px;
  line-height: unset;
}

.danger-inner-circle.home-static {
  animation: unset;
}

.danger-outer-circle.home-static {
  animation: unset;
}

div.custom-timer-text.home-normal > p {
  font-family: var(--secondary-font);
  font-size: 0.5em;
  font-weight: lighter;
  color: white;
  margin: 0;
  line-height: 0;
}

.experience-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-grow: 1;
  padding-bottom: 5vh;
}

.experience-header.hidden {
  display: none;
}

.experience-title {
  text-align: center;
}

.experience-title h1 {
  font-size: 2rem;
  text-align: center;
}

.experience-title p {
  font-size: 1.5rem;
  font-style: italic;
}

.experience-image-container {
  --image-size: 55vw;
  height: var(--image-size);
  width: var(--image-size);
  background-color: var(--x-03);
  border-radius: 50%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.experience-image-container {
  --image-size: 50vw;
  height: var(--image-size);
  width: var(--image-size);
  background-color: var(--x-03);
  border-radius: 50%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.experience-image {
  max-height: 100%;
  border-radius: 50%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
}

.experience-button {
  width: 55%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1vh;
}

.experience-footer {
  width: 100%;
  height: 15%;
  padding: 2dvh;
}

button.home-chest-available {
  font-size: 1.313rem;
  text-align: left;

  color: var(--x-01);
  height: 90%;
  background: linear-gradient(90deg, var(--x-04) 0%, var(--x-22) 100%);
  box-shadow: none;
  border-radius: 1dvh;

  display: flex;
  align-items: center;
}

.home-chest-available-right {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 2dvh;
}

.home-chest-available-right > span {
  line-height: 1em;
  margin: 0;
}

.home-chest-available-right > span.first {
  font-size: 2em;
}

.home-chest-available-right > span.last {
  font-family: var(--secondary-font) !important;
  font-size: 0.75em;
  font-weight: normal;
}

.home-chest-available-left {
  width: 30%;
  display: flex;
  justify-content: right;
}

.home-chest-available-left > img {
  max-height: 8dvh;
}

.home-chest-countdown {
  height: 90%;
  color: var(--x-23);
  display: flex;
  align-items: center;
  background-color: var(--x-02);
  border-radius: 1dvh;
}

.home-chest-countdown > .home-chest-available-left {
  justify-content: center;
}

.home-chest-countdown > .home-chest-available-left > img {
  max-height: 6.5dvh;
}

@media (max-width: 376px), (max-height: 740px) {
  .experience-body {
    flex-grow: 1;
    padding-bottom: 15vh;
  }

  .experience-title h1 {
    font-size: 1.5rem;
  }

  .experience-title p {
    font-size: 1.2rem;
  }

  .experience-button {
    width: 75%;
    margin-bottom: 10px;
  }

  .experience-image-container {
    --image-size: 65vw;
    max-width: 250px;
  }

  .experience-image {
    max-width: 100%;
  }
}

@media (max-height: 600px) {
  .carousel-inner {
    img {
      width: 40% !important;
    }
  }
}
