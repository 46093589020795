.team-metric-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  border-radius: 8px;
  width: 100%;
  max-width: 600px;
  position: relative;
  margin: 0 auto;
}

.team-metric-bar__title {
  display: flex;
  align-items: center;
  color: white;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 12px;
}

.team-metric-bar__icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.team-metric-bar__progress-container {
  width: 100%;
  padding: 4px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.team-metric-bar__progress {
  width: 100%;
  height: 10px;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}

.team-metric-bar__fill {
  height: 100%;
  transition: width 0.4s ease-in-out;
  border-radius: 5px;
}

.team-metric-bar__categories {
  position: relative;
  width: 100%;
  height: 40px;
  margin-top: 16px;
}

@media screen and (min-width: 1024px) {
  .team-metric-bar {
    max-width: 800px;
  }
}
