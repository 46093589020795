.info-prize-container {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  overflow: hidden;
}

.info-prize-img {
  background-size: cover;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 7dvh;
}

.info-prize-info {
  -webkit-backdrop-filter: blur(6.3px);
  backdrop-filter: blur(6.3px);
  background: linear-gradient(1turn, #1d252a00, #1d252a33 89.36%);
  border-radius: 5%;
  font-size: 0.8em;
  margin: 2dvh;
  min-height: 30dvh;
  padding: 2dvh;
  z-index: 5;
}

.jigsaw-info-header {
  display: flex;
  flex-flow: column;
  gap: 0.5dvh;
}

.hot-info {
  width: fit-content;
  padding: 0.2dvh 1.5dvw 0.2dvh 1.5dvw;
  border-radius: 2.5dvw;
  background-color: rgba(255, 255, 255, 0.35);
  display: flex;
  flex-direction: row;
  gap: 1dvw;
  align-items: center;
}

.hot-info span {
  color: var(--x-16);
}

.hot-info img {
  width: 3dvw;
}

.info-prize-img-back-btn {
  position: absolute;
  top: 10dvh;
  left: 2dvh;
  padding: 0.5dvh;
  z-index: 20;

  width: 3.5dvh;
  height: 3.5dvh;
  border: 0.75dvw solid #fff;
  border-radius: 50%;
}

.info-prize-info-detail {
  display: block;
  text-align: left;
  font-size: small;
  margin-bottom: 10px;
  padding: 10px;
}

.info-reward-info-title {
  display: block;
  text-align: left;
  font-size: x-large;
  margin-bottom: 10px;
  padding: 10px;
}

.info-reward-info-detail {
  display: block;
  text-align: left;
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 10px;
  padding: 10px;
}

.info-reward-info-requirement {
  display: block;
  text-align: left;
  font-size: 16px;
  margin-bottom: 10px;
  padding: 10px;
}

/*buttons part*/
.info-prize-btn-img {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.btn-img {
  height: 25px;
  width: auto;
  margin-left: -10px;
  margin-right: 10px;
}

.redeemed-message {
  margin: 14px auto;
  text-align: center;
  color: #07a449;
}

.locked-message {
  margin: 14px auto;
  text-align: center;
  color: #e2424a;
}
.btn-info-prize {
  margin: 10px;
  margin-left: 5%;
}
