.progress-container {
  border-radius: 15px;
  background-color: #171a1e;
  width: 100%;
  margin-top: 1dvh;
  z-index: 20;
  height: 69px;
}

.progress-inner {
  margin-top: 12px;
  margin-left: 12px;
  margin-bottom: 18px;
  margin-right: 12px;
  height: 39px;
}

.progress-inner img {
  position: relative;
  top: -54%;
  left: 90%;
}

.progress-info {
  display: flex;
  width: 92%;
}

.progress-text {
  flex: 1;
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: 400;
  color: #ffffff;
}

.progress-hits-container {
  flex: 1;
  text-align: right;
}

.progress-hits-container span {
  height: 21px;
  font-family: 'Radio Canada Big';
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 100%;
  color: #f0f2f5;
}

.progress-bar-background {
  max-width: 95%;
  height: 6px;
  background: #292f36;
  border-radius: 64px;
  position: relative;
  bottom: -9px;
}
.progress-bar {
  height: 6px;
  border-radius: 64px;
  position: absolute;
  z-index: 10;
  background: linear-gradient(270deg, #b3f131 -3.13%, #abe3f8 53.59%, #ffef7c 100%);
}
