.stats-profile-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 3dvh;
  padding-left: 6dvw;
  padding-right: 6dvw;
}

.stats-profile-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px;
  width: 41dvw;
  height: 7.5dvh;
  background: #171a1e;
  border: 1px solid var(--neutrals-03);
  border-radius: 0.7em;
}

.stats-profile-item-info {
  padding-left: 3dvw;
  display: flex;
  flex-direction: column;
  justify-content: left;
}

.stats-profile-item-info-value {
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: 500;
  font-size: 1.2em;
}

.stats-profile-item-info-title {
  font-family: var(--secondary-font);
  font-weight: 500;
  font-style: normal;
  color: var(--neutrals-04);
  font-size: 0.73em;
}
