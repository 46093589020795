.privacy-policy {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: var(--neutrals-02);
  padding: 32px;
  color: var(--x-07);
}

.privacy-policy__title {
  font-size: 36px;
  font-weight: 800;
  color: var(--x-06);
  margin-bottom: 16px;
}

.privacy-policy__card {
  width: 100%;
  max-width: 900px;
  background-color: var(--neutrals-03);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  padding: 32px 0;
}

.privacy-policy__content {
  text-align: justify;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: var(--x-04) transparent;
  color: var(--x-07);
  font-size: 16px;
  line-height: 1.6;
  max-height: 60vh;
  padding: 0 32px;
}

.privacy-policy__content h3 {
  color: var(--x-04);
  font-size: 22px;
  font-weight: 700;
  margin-top: 24px;
  margin-bottom: 12px;
}

.privacy-policy__content h1,
.privacy-policy__content h2,
.privacy-policy__content h3 {
  text-align: left;
}

.privacy-policy__content p {
  font-size: 16px;
  margin-bottom: 12px;
}

.privacy-policy__content strong {
  color: var(--x-06);
}

.privacy-policy__content ul {
  list-style-type: disc;
}

.privacy-policy__content ul li {
  margin-bottom: 8px;
}

.privacy-policy__content a {
  color: var(--x-04);
  text-decoration: none;
  font-weight: 600;
}

.privacy-policy__content a:hover {
  text-decoration: underline;
}

.privacy-policy__footer {
  width: 100%;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
  margin-bottom: 16px;
}

.privacy-policy__checkbox {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 12px;
  width: 100%;
}

.privacy-policy__checkbox input {
  appearance: none;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  border: 2px solid var(--x-03);
  border-radius: 4px;
  background-color: var(--x-02);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  position: relative;
}

.privacy-policy__checkbox input:checked {
  background-color: var(--x-04);
  border-color: var(--x-04);
}

.privacy-policy__checkbox input:checked::after {
  content: '✔';
  font-size: 16px;
  color: var(--x-01);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
}

.privacy-policy__checkbox label {
  font-size: 18px;
  color: var(--x-07);
  cursor: pointer;
  flex-grow: 1;
  line-height: 1.4;
}

.privacy-policy__button {
  width: 100%;
  max-width: 300px;
  padding: 12px;
  background-color: var(--x-04);
  color: var(--x-01);
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 16px;
  transition: background 0.3s ease-in-out;
  outline: none;
}

.privacy-policy__button:hover {
  background-color: var(--x-05);
}

.privacy-policy__button:focus {
  outline: none;
}

.privacy-policy__button--disabled {
  background-color: var(--x-03);
  color: var(--x-07);
  cursor: not-allowed;
  pointer-events: none;
}

.privacy-policy__button--disabled:focus {
  background-color: var(--x-03);
}

@media (max-height: 720px) {
  .privacy-policy__content {
    max-height: 48vh;
    overflow-y: auto;
  }

  .privacy-policy__footer {
    margin-top: 8px;
  }
}

@media (max-width: 480px) {
  .privacy-policy {
    padding: 16px;
  }

  .privacy-policy__title {
    font-size: 28px;
    text-align: center;
  }

  .privacy-policy__card {
    padding: 16px 0;
  }

  .privacy-policy__content {
    padding: 0 16px;
  }

  .privacy-policy__checkbox label {
    font-size: 16px;
  }
}
