.client-app body {
  font-family: specimen, 'Lato';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

.client-app code {
  font-family: specimen, 'Lato';
}

.Init > h1,
.Init > p {
  color: black !important;
}

.admin-text {
  color: #444444 !important;
}
