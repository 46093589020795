@import url('https://fonts.googleapis.com/css2?family=Radio+Canada+Big:ital,wght@0,400..700;1,400..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Radio+Canada+Big:ital,wght@0,400..700;1,400..700&family=Space+Grotesk:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.client-app * {
  font-family: var(--primary-font);
  font-weight: 700;
}

body {
  color: white;
}

.client {
  margin: 0;
  color: #fff;
  background-color: black;
  display: block;
  justify-content: center;
  align-items: center;
  min-height: 100dvh;
}

.client-app {
  height: 100dvh;
  display: flex;
  flex-direction: column;
  background-color: var(--x-01);
}

main {
  color: white;
  flex: 1;
  overflow: scroll;
}

div.sub-container {
  margin-left: 1em;
  margin-right: 1em;
  margin-bottom: 1em;
}
