.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}

.spinner {
  background-color: var(--x-02);
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.spinner svg {
  height: 100vh;
  width: 100vw;
}

.result-block-container .result-block {
  opacity: 1;
}

.faGreen {
  color: green;
}

.faRed {
  color: red;
}

.faYellow {
  color: #ffdd00;
}

.text-center {
  text-align: center;
}

.content-center {
  display: flex;
  justify-content: center;
}

.content-left {
  display: flex;
  justify-content: flex-start;
}

.content-right {
  display: flex;
  justify-content: flex-end;
}

.text-enabled {
  color: green;
}

.text-disabled {
  color: darkgray;
}

.player-experiences__container {
  overflow: hidden;
  font-size: 20px;
  font-weight: bolder;
  margin-top: 65px;
}

.player-experiences__container__table {
  height: 100%;
  overflow: hidden;
}

.player-experiences__container__table-header {
  position: sticky;
  top: 0;
  z-index: 1;
}

.player-experiences__container__table-body {
  height: 100%;
  overflow: auto;
}

.experiences__table-name {
  text-align: center;
  width: 100%;
}

.experiences__column-name {
  width: 31%;
}

.experiences__column-questions {
  text-align: center;
  width: 23%;
}

.experiences__column-active {
  text-align: center;
  width: 23%;
}

.experiences__column-created {
  text-align: center;
  width: 23%;
}

.prizes__column-name {
  width: 31%;
}

.prizes__column-description {
  text-align: justify-all;
  width: 23%;
}

.prizes__column-created {
  text-align: center;
  width: 23%;
}

.prizes__column-amount {
  text-align: center;
  width: 23%;
}

.questions__button-add-question {
  color: #0c63e4;
  background: #e7f1ff;
}

.questions__button-add-option {
  color: #0c63e4;
  background: #e7f1ff;
}

.questions__button-remove-option {
  color: white;
  background: #002583;
}

.orderable-column {
  cursor: pointer;
}

.status-badge {
  min-width: 8em !important;
}

.badge {
  min-width: 5em;
}

.bg-bronze {
  background-color: #cd7f32;
  color: #fff;
}

.bg-silver {
  background-color: #c0c0c0;
  color: #000;
}

.bg-gold {
  background-color: #ffd700;
  color: #000;
}

.question-priority-low {
  background-color: #83c6ef;
  color: #0970f5;
}

.question-priority-medium {
  background-color: #eed06b;
  color: #bb5d15;
}

.question-priority-high {
  background-color: #f8b3a4;
  color: #c52828;
}

.view-icon {
  color: #dad3d3;
  padding: 2px 8px;
}

.view-icon:hover {
  background-color: #ddd7d7;
  border-radius: 0.25rem;
}

tr:hover .view-icon {
  color: #0066f9;
}

.spinner-container-fixed .spinner {
  width: 100%;
  height: 100%;
}

table.middle-aligned > * {
  vertical-align: middle;
}

td.enlarged {
  font-size: 115%;
}

h3 {
  color: #444444;
}

img.experience {
  width: 200px;
  height: 200px;
}
