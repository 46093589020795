.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: 10px;
}

.question-image {
  width: 100%;
  max-width: 350px !important;
  min-width: 250px !important;
  height: auto;
  aspect-ratio: 1/1 !important;
  border-radius: 50% !important;
  border: 1px solid #ddd;
  object-fit: cover;
}
