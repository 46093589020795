.questionary {
  position: fixed;
  top: 0;
  margin: 1dvh 4dvw 2dvh 4dvw;
  display: flex;
  flex-flow: column;
  gap: 1dvh;
  transform-origin: top center;
  width: 100%;
  height: 100%;
}

.container-question-answer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.question-area {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 24px;
}
.question-area > div > p > span {
  font-family: var(--questionary-question-font);
  font-weight: 600;
  font-size: 2.2vh;
}

.question-area > div > p {
  font-family: var(--questionary-question-font);
  font-weight: 600;
}

.question-text {
  font-family: var(--questionary-question-font);
  font-weight: 600;
  font-size: 2.2vh;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
}

.question-text > span {
  font-family: var(--questionary-question-font);
  font-weight: 600;
}

.question-image {
  width: 70%;
  height: 70%;
  max-width: 400px;
  max-height: 400px;
  border-radius: 50%;
  padding: 5px;
  aspect-ratio: 1 / 1;
}

.button-array {
  display: flex;
  flex-direction: column;
  gap: 2dvh;
  align-items: center;
  width: 100%;
  height: 45%;
}

.button-array > button {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  font-size: 1.2em;
  width: 90%;
  height: 18%;
  color: white;

  box-shadow: unset;
  border-style: solid;
  border-color: var(--x-20);
  border-radius: 3dvh;
  border-width: 1px;
  background-color: unset;
}

.button-array > button > span {
  font-family: var(--secondary-font);
  font-weight: normal;
}

.button-array > button.incorrect {
  color: black;
  background-color: var(--x-17);
  border-style: none;
}

.button-array > button.correct {
  color: black;
  background-color: var(--x-04);
  border-style: none;
}

div.title-box {
  --s: 60dvw;
  --p: 1em;
  height: var(--s);
  width: var(--s);
  max-width: 400px;
  max-height: 400px;
  border-radius: 50%;
  background: var(--x-03);
  color: #fff;
  margin: 30px auto;
  font-size: 1.25rem;
  text-align: justify;
}

.title-box > p {
  position: relative;
  text-align: center;
  top: 50%;
  translate: 0 -60%;
}

.title-box i,
.title-box::before {
  content: '';
  float: left;
  height: 100%;
  width: 50%;
  shape-outside: radial-gradient(farthest-side at right, transparent calc(100% - var(--p)), #fff 0);
}

.title-box i {
  float: right;
  shape-outside: radial-gradient(farthest-side at left, transparent calc(100% - var(--p)), #fff 0);
}

.title-question {
  text-align: center;
  font-size: xx-large;
}

.incorrect-modal svg {
  position: fixed;
  height: 100%;
  width: auto;
  top: 0;
  left: 0;
  transform: translateX(calc((100dvw - 100dvh) / 2));
  z-index: 30;
}

.fine-counter {
  position: fixed;
  left: 0;
  width: 100%;
  top: 50%;
  translate: 0 -50%;
  text-align: center;
  color: var(--x-16);
  font-size: 6em;
  z-index: 31;
}

@media (min-width: 768px) {
  .title-box {
    --s: 35dvw;
    font-size: 1.5rem;
    padding: 0.5em;
  }

  .title-box > p {
    font-size: 2rem;
    text-align: center;
    font-weight: bold;
  }

  .button-array > button {
    font-size: 1.3em;
    height: 12%;
    padding: 0.6em 0;
  }
}

@media (max-width: 376px), (max-height: 740px) {
  .container-question-answer {
    margin-top: -5px;
  }

  .title-box {
    font-size: 1.2rem !important;
    margin: 5px auto !important;
    padding: 0.3em 0 !important;
  }

  .question-image {
    width: 50%;
    height: 50%;
    padding: 2px;
  }

  .question-area {
    height: 38vh;
    margin-top: 10px;
    margin-bottom: 12px;
  }

  .question-area > div > p {
    line-height: 25px;
  }

  .button-array {
    gap: 1dvh;
    height: 40%;
  }

  .button-array > button {
    font-size: 1.1em;
    padding: 0.5em 0;
    height: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
