.metrics-title-container {
  display: flex;
  padding-top: 2dvh;
  padding-left: 6dvw;
  width: 100%;
}

.metrics-title {
  font-family: 'Radio Canada Big';
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 100%;
  color: #ffffff;
}

.metrics-badges-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 2dvh;
  padding-left: 6dvw;
  padding-right: 6dvw;
  padding-bottom: 1dvh;
}

.metrics-badge-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  background: linear-gradient(180deg, #1b1b21 0%, #292f36 100%);
  border: 1px solid #747c8a;
  border-radius: 12px;
}

.metrics-badge-active {
  border: 3px solid #7bb600;
  background: linear-gradient(0deg, rgba(179, 241, 49, 0.15), rgba(179, 241, 49, 0.15)),
    linear-gradient(180deg, #1b1b21 0%, #292f36 100%);
}

.metrics-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 2dvh;
  padding-left: 4dvw;
  padding-right: 4dvw;
  padding-bottom: 2dvh;
}

.metric-level {
  display: flex;
  justify-content: center;
  width: 117px;
}

.metric-level-text {
  font-family: 'Radio Canada Big';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
  color: #ffffff;
}

.metric-level-text-active {
  color: #b3f131;
}
