.container-list {
  padding-top: 10dvh;
  padding-bottom: 15dvh;
}

.prize-list-container {
  overflow: hidden;
  width: 100dvw;
  height: 62dvh;
  display: block;
  max-height: 450px;
}

.prize-list-title {
  margin-bottom: 10px;
  margin-left: 5px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  font-size: 25px;
}

.lock-info-pill {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: center;
  padding: 1.5dvh 1dvh 1.5dvh 1dvh;
  gap: 1.5dvw;

  max-height: 1.5dvh;
  margin-left: 2dvw;
  border-radius: 2.5dvh;
  font-size: 1.5dvh;

  color: var(--x-04);
  background: var(--x-18);
}

.lock-info-pill img {
  height: 2dvh;
}

.lock-info-pill.locked {
  color: var(--x-16);
  background: var(--x-19);
}

.prize-list {
  width: 100%;
  overflow-x: auto;
  display: flex;
}

.prize-container {
  display: block;
  margin-left: 2.5dvw;
  width: 60dvw;
  min-width: 70dvw;
  padding-top: 0;
  overflow: hidden;
}

.prize-jigsaw-image {
  min-width: 70dvw;
  min-height: 20dvh;
  width: 60dvw;
  height: 25dvh;
  margin-bottom: 1dvh;
  position: relative;
  display: block;
}

.prize-jigsaw-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 1.5dvh;
  margin-bottom: 0;
  padding-bottom: 0;
}

.prize-jigsaw-grid {
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
}

.prize-jigsaw-info {
  display: block;
  margin-left: 1dvw;
  width: 100%;
  height: auto;
  font-size: 0.8em;
  line-height: normal;
}

.jigsaw-info-title {
  display: flex;
  align-items: center;
  gap: 1dvh;
  max-height: 3dvh;
}

.jigsaw-info-title span:first-child {
  width: 70%;
  font-size: 1.5em;
  line-height: 0.75em;
}

.jigsaw-info-title div {
  width: 30%;

  display: flex;
  justify-content: left;
  align-items: center;
  height: 3dvh;
}

.jigsaw-info-title div span {
  font-size: 1.5em;
  width: 75%;
}

.partpiece {
  height: 20px;
  width: 20px;
  margin-right: 10px;
}
