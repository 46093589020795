.category-badge {
  position: absolute;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  padding: 8px 12px;
  border-radius: 20px;
  width: auto;
  min-width: 80px;
  transition: opacity 0.3s;
}

.category-badge__text {
  color: white;
  font-weight: bold;
}

.category-badge__icon {
  width: 24px;
  height: 24px;
  margin-right: 6px;
}

.category-badge--disabled {
  opacity: 0.3;
  background: #5b5b5b;
  color: #666;
  padding: 2px 0;
  margin-top: 8px;
  min-width: 60px;
}

.category-badge--active {
  opacity: 1;
  color: white;
  display: flex;
  align-items: center;
  z-index: 1;
}

.category-badge__indicator {
  position: absolute;
  top: -16px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 10px solid white;
}

.category-badge__indicator--disabled {
  top: -24px;
}
