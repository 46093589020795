.client-app h1 {
  justify-content: center;
}

div.sub-container.last {
  margin-bottom: 1em;
}

button.booster-button {
  margin: 2em;
  position: relative;
  border-radius: 49%;
  width: 10em;
  height: 10em;
  left: calc(50% - 14em / 2);
  background: #ee5655;
  box-shadow: inset 0px -8px 0px rgba(0, 0, 0, 0.5);
}

button.booster-button:active {
  background: #c55b5b;
  box-shadow: none;
}

button.booster-button span {
  position: relative;
  font-size: 2em;
  color: white;
  margin-left: 0;
  top: -0.2em;
}

.modal-container {
  width: 100dvw;
  background-color: rgb(32 47 54 / 95%);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 7vh;
  color: white;
  z-index: 35;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 1vh;
}

.modal-container > h3 {
  color: white;
  margin: 5vw;
}
