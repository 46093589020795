footer {
  min-height: 7dvh;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--x-02);
  box-shadow: none;
}

.frame {
  display: flex;
  gap: 45px;
  max-height: 6vh;
}

.frame img {
  max-width: 100%;
  height: auto;
  padding-bottom: 5px;
}

.frame div .text-wrapper {
  color: var(--x-06);
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.26px;
}

.frame div .text-wrapper.active {
  color: var(--x-04);
}
